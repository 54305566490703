import { IhsSamAuthorityEnvCode, IpreoAccountAuthorityEnvCode } from '../app/authorization/contracts/auth-client-settings';
import { Environment as ExternalParticipantsEnvironment } from '@ipreo/bd-external-participants';
import { Environment as CompanyContactsEnvironment } from '@ipreo/company-contacts-modal';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: 'perf',
  baseUrl: 'https://api.perf.bdc.ipreo.com/activity',
  hubBaseUrl: 'https://perf.event-hub.ipreo.com',
  domain: 'https://bdc.perf.bdc.ipreo.com',
  ipreoAccount: {
    authority: IpreoAccountAuthorityEnvCode.Staging,
    clientId: 'billfold-perf'
  },
  ihsSam: {
    clientId: 'issuersolutions-capitalaccess-eventmanagement-dev-640GPrhe18',
    authority: IhsSamAuthorityEnvCode.Staging,
  },
  externalParticipantEnvironment: ExternalParticipantsEnvironment.Perf,
  companyContactsEnvironment: CompanyContactsEnvironment.Perf,
  baseRedirectionPath: '/activity/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
