export enum IpreoAccountAuthorityEnvCode {
  Staging,
  Production
}

export enum IhsSamAuthorityEnvCode {
  Staging,
  Production
}

export interface AuthClientSettings {
  billfoldAuthSettings: BillfoldAuthClientSettings;
  ihsSamSettings: IhsSamClientSettings;
}

export interface BillfoldAuthClientSettings {
  clientId: string;
  authority: IpreoAccountAuthorityEnvCode;
  scope: string[];
  baseRedirectionPath: string;
}

export interface IhsSamClientSettings {
  clientId: string;
  authority: IhsSamAuthorityEnvCode;
  scope: string[];
  baseRedirectionPath: string;
}
